import { TFunction } from '@wix/yoshi-flow-editor';
import { Field, FieldOrigin } from '../../../../../../types';

const hasTranslation = (t: TFunction, key: string) => t(key) !== key;

const appendPostfix = (label: string, postfix: number | null) => {
  return postfix ? `${label} ${postfix}` : label;
};

export const getFieldLabel = (
  t: TFunction,
  field: Pick<Field, 'key' | 'index' | 'label' | 'fieldOrigin'>,
  isMultilingualEnabled = false,
) => {
  const { key, index, label, fieldOrigin } = field;
  const postfix = index ? index + 1 : null;

  if (!isMultilingualEnabled && fieldOrigin !== FieldOrigin.CUSTOM) {
    const isLabelTranslated = hasTranslation(t, key);
    const fieldLabel = isLabelTranslated ? t(key) : label;
    return appendPostfix(fieldLabel, postfix);
  }

  const isKeyLabel = !label || key === label;
  const fieldLabel = isKeyLabel ? t(key) : label;

  return appendPostfix(fieldLabel, postfix);
};
