import React, { FC, useEffect, useState } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { TextField } from 'wix-ui-tpa/TextField';
import { useField as useFormikField } from 'formik';

import { Field, FieldWrapper } from '../../../../../../types';
import { FieldLabel } from '../../FieldLabel';
import { getFieldLabel } from '../utils/getFieldLabel';
import { FieldPrivacyIcon } from '../../FieldPrivacyIcon';
import { DataHook } from '../../../../../../constants/DataHook';
import { classes } from '../Text/Text.st.css';

type ErrorType = { value: string } | undefined;

export const NumberField: FC<FieldWrapper> = ({
  theme,
  formikFieldSelector,
}) => {
  const { t } = useTranslation();
  const { multilingual } = useEnvironment();
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const [formikField, meta, helpers] =
    useFormikField<Field>(formikFieldSelector);
  const [htmlValidityState, setHtmlValidityState] = useState<ValidityState>();
  const [error, setError] = useState<string>();
  const fieldValidationMessage = (meta.error as unknown as ErrorType)?.value;

  useEffect(() => {
    if (fieldValidationMessage) {
      setError(fieldValidationMessage);
    } else if (htmlValidityState?.valid === false) {
      setError(t('app.widget.fields.validation.invalid-number'));
    } else {
      setError(undefined);
    }
  }, [t, fieldValidationMessage, htmlValidityState?.valid]);

  const field = formikField.value;
  const { required, infoTooltipText, value, key, id } = field;

  return (
    <div data-hook={key}>
      <FieldLabel
        text={getFieldLabel(t, field, multilingual.isEnabled)}
        htmlFor={id}
        isFieldRequired={required}
        infoTooltipText={infoTooltipText}
      />
      <FieldPrivacyIcon
        formikFieldSelector={formikFieldSelector}
        data-hook={DataHook.FieldPrivacyIcon}
      >
        <TextField
          id={id}
          className={classes.field}
          data-hook={DataHook.TextField}
          value={
            value ??
            '' /* Need to pass empty string, for formik form reset to work*/
          }
          theme={theme}
          type="number"
          errorMessage={error}
          withClearButton={isClearButtonVisible}
          error={!!error}
          onClear={() => helpers.setValue({ ...field, value: '' })}
          onChange={(event) => {
            helpers.setValue({
              ...field,
              value: event.target.value,
            });
            setClearButtonVisibility(!!event.target.value);
            setHtmlValidityState(event.target.validity);
          }}
          onFocus={() => setClearButtonVisibility(!!value)}
          onBlur={() => {
            setTimeout(() => setClearButtonVisibility(false), 100);
          }}
        />
      </FieldPrivacyIcon>
    </div>
  );
};
