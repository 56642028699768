import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { useField as useFormikField } from 'formik';

import {
  AddressSubfield,
  DropdownValue,
  Field,
  FieldPrivacy,
  FieldWrapper,
  ProfilePrivacyStatus,
} from '../../../../../../types';
import { PrivacyIcon } from '../../PrivacyIcon';
import { SectionHeader } from '../../SectionHeader';
import { classes } from './Address.st.css';
import { FieldPrivacyModal } from '../../../modals';
import { useModal } from '../../../../../../contexts/modals/hooks';
import {
  useCountryDropdownOptions,
  useMember,
  useSiteContext,
} from '../../../../../../contexts/widget';
import { getFieldLabel } from '../utils/getFieldLabel';
import { DataHook } from '../../../../../../constants/DataHook';
import {
  ADDRESS_LINE_FIELD_KEY,
  CITY_FIELD_KEY,
  COUNTRY_FIELD_KEY,
  POSTAL_CODE_FIELD_KEY,
  SUBDIVISION_FIELD_KEY,
} from '../../../../../../server/constants';
import { DropdownField } from '../Dropdown';
import { Text } from '../Text';
import { DropdownOptionProps } from 'wix-ui-tpa/dist/src/components/Dropdown/DropdownOption';

const getSubfieldSelectorFactory = (
  subfields: AddressSubfield[],
  baseSelector: string,
) => (key: string) => {
  const fieldIndex = subfields.findIndex((subfield) => subfield.key === key);

  return `${baseSelector}.value[${fieldIndex}]`;
};

export const AddressField: FC<FieldWrapper> = ({
  theme,
  formikFieldSelector,
}) => {
  const { showModal } = useModal();
  const { privacyStatus } = useMember();
  const { isCustomProfileEnabled } = useSiteContext();
  const countryDropdownOptions = useCountryDropdownOptions();
  const [formikField, , helpers] = useFormikField<Field>(formikFieldSelector);
  const { t } = useTranslation();
  const { isEnabled: isMultilingualEnabled } = useEnvironment().multilingual;

  const isPublicProfile = privacyStatus === ProfilePrivacyStatus.PUBLIC;
  const field = formikField.value;
  const subfields = field.value as AddressSubfield[];
  const getSubfieldSelector = getSubfieldSelectorFactory(
    subfields,
    formikFieldSelector,
  );
  const [countrySubfield, ,] = useFormikField<AddressSubfield['value']>(
    `${getSubfieldSelector(COUNTRY_FIELD_KEY)}.value`,
  );
  const [stateSubfield, , stateFieldHelpers] = useFormikField<
    AddressSubfield['value']
  >(`${getSubfieldSelector(SUBDIVISION_FIELD_KEY)}.value`);

  const shouldShowPrivacyIcon =
    isCustomProfileEnabled &&
    isPublicProfile &&
    field.isPrivacyEditable &&
    !field.index;

  const countrySelectedId = (countrySubfield?.value as DropdownValue)
    ?.selectedId;
  const selectedStateId = (stateSubfield?.value as DropdownValue)?.selectedId;
  const stateDropdownOptions = useMemo(
    () =>
      countryDropdownOptions.find((option) => option.id === countrySelectedId)
        ?.states ?? [],
    [countryDropdownOptions, countrySelectedId],
  );

  const onPrivacyChange = (newPrivacy: FieldPrivacy) => {
    const updatedField = { ...field, privacy: newPrivacy };
    helpers.setValue(updatedField);
  };
  const openPrivacyModal = () =>
    showModal(FieldPrivacyModal, { field, onChange: onPrivacyChange });

  const privacyIconButton = shouldShowPrivacyIcon ? (
    <div className={classes.privacyIcon}>
      <PrivacyIcon
        data-hook={DataHook.AddressFieldPrivacyIcon}
        privacy={field.privacy}
        onClick={openPrivacyModal}
      />
    </div>
  ) : null;

  const prevStateOptionsRef = useRef<DropdownOptionProps[]>();
  useEffect(() => {
    const hasDropdownOption = stateDropdownOptions.some(
      (option) => option.id === selectedStateId,
    );
    if (stateDropdownOptions.length && !hasDropdownOption && selectedStateId) {
      stateFieldHelpers.setValue('');
    } else if (
      !stateDropdownOptions.length &&
      prevStateOptionsRef.current?.length
    ) {
      stateFieldHelpers.setValue('');
    }
    prevStateOptionsRef.current = stateDropdownOptions;
  }, [stateDropdownOptions, stateFieldHelpers, selectedStateId]);

  return (
    <>
      <SectionHeader
        title={
          <div className={classes.sectionTitle}>
            {getFieldLabel(t, field, isMultilingualEnabled)} {privacyIconButton}
          </div>
        }
        className={classes.addressHeader}
      />

      <Text
        theme={theme}
        formikFieldSelector={getSubfieldSelector(ADDRESS_LINE_FIELD_KEY)}
      />
      <Text
        theme={theme}
        formikFieldSelector={getSubfieldSelector(CITY_FIELD_KEY)}
      />
      <Text
        theme={theme}
        formikFieldSelector={getSubfieldSelector(POSTAL_CODE_FIELD_KEY)}
      />
      <DropdownField
        theme={theme}
        formikFieldSelector={getSubfieldSelector(COUNTRY_FIELD_KEY)}
        options={countryDropdownOptions}
      />
      {stateDropdownOptions.length ? (
        <DropdownField
          theme={theme}
          formikFieldSelector={getSubfieldSelector(SUBDIVISION_FIELD_KEY)}
          options={stateDropdownOptions}
        />
      ) : null}
    </>
  );
};
